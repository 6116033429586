
"use client";

import { ClickAwayListener } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import { Close, Save } from "@mui/icons-material";

import TextInputNormal from "../input/TextInputNormal";

type ModalProps = {
  imageUrl?: string | null;
  onClose: () => void;
  isOpen: boolean;
  onChange: (newUrl: string) => void;
};

export default function ModalMediaEditor({
  imageUrl = "",
  onClose,
  isOpen,
  onChange,
}: ModalProps) {
  const scrollerRef = useRef(null);
  const [urlInputValue, setUrlInputValue] = useState("");
  const [loadedUrl, setLoadedUrl] = useState("");

  // const [callback, setCallback] = useState<any>(null);

  useEffect(() => {
    setUrlInputValue(imageUrl || "");
    setLoadedUrl(imageUrl || "");
  }, [isOpen, imageUrl]);

  function loadImage(url: string) {
    setLoadedUrl(url);

    (scrollerRef?.current as any).scrollTo &&
      (scrollerRef.current as any).scrollTo(0, 0);
  }

  return (
    <div
      id="modal-media-editor-top"
      className={`${
        isOpen ? "block" : "hidden"
      } fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50
            flex flex-col items-center justify-center
            `}
    >
      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    max-w-[650px] max-h-[95dvh] overflow-y-auto w-full"
            ref={scrollerRef}
          >
            <div className="w-full">
              <div className="bg-dark-blue-100">
                <div className="sticky top-0 z-10 bg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                  <CardTitle text="Choose media" />
                  <RoundedLinkButton
                    icon={<Close fontSize="small" />}
                    onClick={onClose}
                  />
                </div>
                <div className="pt-2"></div>

                <CardInner>
                  <div className="flex flex-row items-start justify-between">
                    <div className="flex items-center justify-center p-2">
                      <div className="overflow-hidden rounded-lg border-2 border-[#000000]   bg-[#111010c6]">
                        <img
                          width={100}
                          height={100}
                          alt=""
                          src={loadedUrl || ""}
                        />
                      </div>
                    </div>
                    <div className="p2">
                      <RoundedLinkButton
                        icon={<Save fontSize="small" />}
                        title="Save"
                        onClick={() => {
                          onChange(loadedUrl);
                          onClose();
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <p className="text-sm text-[#ddd]">Load from URL: </p>

                    <div className="pt-2"></div>

                    <div className="flex gap-2">
                      <TextInputNormal
                        fullWidth
                        type="url"
                        onChange={(e) => {
                          setUrlInputValue(e.target.value);
                        }}
                        value={urlInputValue}
                        placeholder="eg: http://.../image.png"
                      />
                      <RoundedLinkButton
                        icon={null}
                        title="Load"
                        onClick={() => {
                          loadImage(urlInputValue);
                        }}
                      />
                    </div>

                    <div className="pt-2"></div>

                    <p>Load from media: </p>

                    <div className="pt-3"></div>

                    <div className="flex flex-wrap justify-center">
                      {Array.from({ length: 100 }).map((_, index) => {
                        return (
                          <div
                            key={index}
                            className="w-fit h-fit overflow-hidden rounded-lg border-2 border-[#000000]   bg-[#111010c6] relative"
                          >
                            <img width={100} height={100} alt="" src={""} />
                            <div
                              className="absolute top-0 left-0 right-0 bottom-0 hover:bg-[#2a2a2a9f] cursor-pointer"
                              onClick={() => {
                                // (window as any).c  = scrollerRef.current;
                                loadImage("");
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CardInner>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

