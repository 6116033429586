
import { Construction } from "@mui/icons-material";

import DashboardLayout from "./dashboard-layout";

export default function Settings() {
  return (
    <DashboardLayout>
      <main className="h-full w-full">
        <div className=" text-center relative top-[50%]  translate-y-[-50%]">
          <Construction
            fontSize="large"
            sx={{
              fontSize: 120,
              p: 4,
              borderRadius: "50%",
              backgroundColor: "#00000015",
            }}
          />
          <div className="text-[#4979c0] font-bold text-2xl pt-5 pb-2">
            Nothing to customize!
          </div>
          <div className="text-xs">
            Any customizable setting will appear here.
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
}

