

import { forwardRef, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";



function SummerNoteEditor({
  content,
  className,
  parentRef,
}: {
  content: any;
  className: string;
  parentRef: any;

}, editorRef: any) {


  const params = useParams();

   
  const url = globalThis.location.origin;
  const [height, setHeight] = useState(100);

  // ref = useRef(null);






  // const editorRef = useRef(null);




  useEffect(() => {
    if (parentRef.current) {

      setHeight(parentRef.current.clientHeight - 220 - (window.innerHeight - parentRef.current.clientHeight));
    }
  }, [parentRef]);


  useEffect(() => {
    if (editorRef.current) {
      //editorRef is valid;
      // console.log("height is  : ", height);



      (editorRef.current as any).srcdoc = `
            <!DOCTYPE html>
            <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>without bootstrap</title>
            <script src="${url}/vendors/jquery/jquery-3.7.1.slim.min.js"></script>
            <link href="${url}/vendors/summernote/summernote-lite.min.css" rel="stylesheet">
            <script src="${url}/vendors/summernote/summernote-lite.min.js"></script>
          </head>
          <body>
        
            <div style="width:100%;xheight:100%;background-color:white;border-radius:8px">
            
                <div style="width:100%;xheight:100%"  id="summernote"></div>
            </div>
        
            <script>
        
        
            $('#summernote').summernote({
                // placeholder: 'Hello stand alone ui',
                fontNames: ['Hind Siliguri', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto'],
                fontNamesIgnoreCheck: ['Hind Siliguri'],
                tabsize: 2,
                height: ${height},
                fontSizes:['8', '9', '10', '11', '12', '13', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '40', '44', '48', '52', '56', '60', '64', '68', '72', '76', '78'],
                toolbar: [
                  ['style', ['style']],
                  ['action', ['undo', 'redo']],
                  ['font', ['bold', 'underline','italic', 'clear', 'superscript', 'subscript']],
                  ['fontstyle', [ 'fontname',  ]],
                  ['fontsize', [ 'fontsize','fontsizeunit']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph', 'height']],
                  ['table', ['table']],
                  ['insert', ['link', 'picture', 'video', 'xtable' , 'xhr']],
                  ['view', ['xundo', 'xredo', 'xfullscreen', 'codeview', 'help']]
                ],
                buttons: {
                  mfontsize: FontSizeDropdownButton, // Define the custom font size dropdown button
                },
              });


              // Custom font size dropdown button component

              function FontSizeDropdownButton(context) {


                // Build the font size dropdown menu
                const dropdown = $(
                  '<ul class="dropdown-menu custom-font-size-list" role="menu"></ul>'
                );
                const fontSizeList = ['8', '9', '10', '11', '12', '13', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '40', '44', '48', '52', '56', '60', '64', '68', '72', '76', '78'];

                let options = '';

                fontSizeList.forEach((fontSize) => {
                 options+= \`<option value="\${fontSize}">\${fontSize}</option>\`;
                  
                });


                const onSC = (value) => {
                  $('#summernote').summernote('fontSize', value);


                  // context.invoke('editor.insertNode', context.invoke('editor.createRange').wrapContents($('<span></span>').css('font-size', value)));
                }


              
                const button = $.summernote.ui.button({
                  className: 'font-size-toggle',
                  contents: \`
                  <span>XXX</span>
                  <select onchange="onSC(this.value)" id="smn_fs_sl" style="position:absolute;left:1px;top:1px;bottom:1px;right:1px;width:48px;background-color:white;border:2px solid red;border-radius:2px;outline:none;border:none;cursor:pointer">
                    \${options}
                    
                  </select>
                  \`,
                  tooltip: 'Font Size',
                  click: function () {
                    // alert('hi');
                    // debugger;
                    // smn_fs_sl.click();

                    // var event = document.createEvent('MouseEvents');
                    // event.initMouseEvent('mouseup', true, true, window)
                    // smn_fs_sl.dispatchEvent(event);

                    // smn_fs_sl.size = smn_fs_sl.length;

                  },
                });


                // Append the dropdown to the body
                // $('body').append(dropdown);

                return button.render();
              };







            $('#summernote').summernote('code', \`${content.doc || ''}\`);


            function getCode(){
              return $('#summernote').summernote('code');
            }

            function xsave(){

                var markupStr = $('#summernote').summernote('code');



                const payload = {
                    "content_id": ${content.content_id},
                    "doc": markupStr,
                    "app_id": ${Number(params.app_id)},
                };

                fetch("${url}/api/docs/upsert", {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(payload), // body data type must match "Content-Type" header
                  }).then(res=>res.json())
                  .then(json=>{
                    alert("Document has been saved successfully");
                  });
            }
        
            </script>
          </body>
        </html>
        
        
            `;

      editorRef.current.getCode = () => {
        return editorRef.current.contentWindow.getCode();
      }
    }

  }, [content, editorRef, height, url]);



  return (
    <div className={className}>
      <iframe width="100%" height="100%" className="rounded-lg w-full h-full xoverflow-visible" ref={editorRef} ></iframe>
    </div>
  )
}



export default forwardRef(SummerNoteEditor);