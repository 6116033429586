
import { useEffect, useRef } from "react";

type ContentDocType = {
  content_doc_id: number;
  content_id: number;
  doc_type_id: number;
  ordinal: number;
  value: string;
};

export default function ContentPreviewerDoc({ doc }: { doc: string }) {
  const frameRef = useRef(null);

  useEffect(() => {
    if (frameRef.current) {
      (frameRef.current as any).srcdoc = `
        <!DOCTYPE html>
        <html lang="en-US">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
        </head>
        <body style="xfont-size:14px; xheight:100%;width:120%;transform: scale(0.8); transform-origin: 0 0;">
            
            ${doc || ""}

        </body>
        </html>
        `;
    }
  }, [doc, frameRef]);

  return (
    <div className="flex w-full items-center justify-center py-2 px-0.5 sm:px-2">
      <div className="bg-[#ddd] rounded-md flex flex-col w-full xh-[400px] py-2 flex-grow-0 flex-shrink xh-full overflow-hidden">
        <div className="overflow-hidden xh-full  w-full xopacity-0">
          <div
            dangerouslySetInnerHTML={{ __html: doc }}
            className="text-black p-4 zoom-[10%]"
          />
        </div>
      </div>
    </div>
  );
}

