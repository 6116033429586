"use client";

import { ClickAwayListener } from "@mui/material";
import { DragEvent, useEffect, useMemo, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import {
  Close,
  Done,
  DragIndicator,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  RoundedCorner,
  Save,
} from "@mui/icons-material";

import TextInputNormal from "../input/TextInputNormal";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";
import DraggableContainer from "./drag-drop-ui/DraggableContainer";
import DroppableContainer from "./drag-drop-ui/DroppableContainer";

type UniqueArray<U> = U extends readonly [infer X, ...infer Rest]
  ? InArray<Rest, X> extends true
    ? ["Encountered value with duplicates:", X]
    : readonly [X, ...UniqueArray<Rest>]
  : U;

type InArray<U, X> = U extends readonly [X, ...infer _Rest]
  ? true
  : U extends readonly [X]
  ? true
  : U extends readonly [infer _, ...infer Rest]
  ? InArray<Rest, X>
  : false;

export default function ModalDraggableItemEditor<
  T extends Record<string, any>
>({
  items,
  title,
  subtitle,
  identifier,
  ordinal,
  filterables,
  onClose,
  isOpen,
  onChange,
}: {
  items: T[];
  identifier: keyof T;
  ordinal: keyof T;
  filterables: UniqueArray<(keyof T)[]>;
  title: keyof T;
  subtitle?: keyof T;
  onClose: () => void;
  isOpen: boolean;
  onChange: (items: T[]) => void;
}) {
  const scrollerRef = useRef(null);

  const [filterBy, setFilterBy] = useState("drag_drop");
  const [sortBy, setSortBy] = useState("asc");

  const [mSortItems, setMSortItems] = useState<T[]>([]);
  const [mDragDropItems, setMDragDropItems] = useState<T[]>([]);

  const modifiedItems = filterBy === "drag_drop" ? mDragDropItems : mSortItems;
  // const modifiedItems = useMemo(
  //     () => filterBy === 'drag_drop' ? mDragDropItems : mSortItems,
  //     [filterBy, sortBy, mDragDropItems, mSortItems]);

  // const [callback, setCallback] = useState<any>(null);

  useEffect(() => {
    if (filterBy !== "drag_drop") {
      setMSortItems((o) => {
        const x = [...o];

        const y = x.sort((ae: any, be: any) => {
          let compare = 0;

          const a = ae[filterBy];
          const b = be[filterBy];

          if (typeof a === "string") {
            compare = String(a).localeCompare(String(b));
          }
          if (typeof a === "number" || typeof a === "bigint") {
            compare = Number(a) - (Number(b) || 0);
          }
          if (typeof a === "boolean") {
            compare = a && !b ? 1 : !a && b ? -1 : 0;
          }

          return sortBy === "asc" ? compare : -compare;
        });

        return functionHandler.modifyOrdinal(y);
      });
    }
  }, [sortBy, filterBy]);

  useEffect(() => {
    setMDragDropItems(functionHandler.modifyOrdinal(items));
    setMSortItems(functionHandler.modifyOrdinal(items));
  }, [isOpen, items]);

  function reOrderModifiedItems(source: number, destination: number) {
    if (destination === source) return;

    setMDragDropItems((ox) => {
      const o = [...ox];
      const itm = o.splice(source, 1)[0];

      // if (destination === 0) {
      //     o.unshift(itm);
      // } else {
      o.splice(destination, 0, itm);
      // }

      return functionHandler.modifyOrdinal([...o]);
    });

    setFilterBy("drag_drop");

    // const mi = modifiedItems;
    // const ms = { ...mi[second] };
    // mi[second] = { ...mi[first] };
    // mi[first] = ms;

    // setModifiedItems([...mi]);
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    console.log("drag end: ", result, provided);

    if (result.reason === "CANCEL" || result.destination?.index === undefined) {
      return;
    }

    console.log("dest : ", result.destination?.index);
    console.log("source : ", result.source.index);

    reOrderModifiedItems(result.source.index, result.destination?.index);
  }

  const functionHandler = {
    modifyOrdinal<T>(items: Array<T>) {
      if(!items || !items.map) return items;
      return items.map((e, i) => {
        return {
          ...e,
          [ordinal]: i,
        };
      });
    },
  };

  return (
    <div
      id="modal-media-editor-top"
      className={`${
        isOpen ? "block" : "hidden"
      } fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50
            flex flex-col items-center justify-center
            `}
    >
      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    max-w-max max-h-[95dvh]  overflow-y-auto  w-full"
            ref={scrollerRef}
          >
            <div className="w-full h-full">
              <div className="bg-dark-blue-100">
                <div className="sticky top-0 pb-2 bg-dark-blue-100 ">
                  <div className=" z-10 xbg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                    <CardTitle text="Sort items" />
                    <RoundedLinkButton
                      icon={<Close fontSize="small" />}
                      onClick={onClose}
                    />
                  </div>
                  <div className="pt-2"></div>

                  <div className="px-3  flex flex-row w-full items-center justify-center content-stretch  gap-1 flex-wrap ">
                    <p className=" flex-shrink-0 flex-grow">Filter by: </p>

                    <select
                      title="Filter by"
                      className=" p-2 rounded-lg text-[#ddd] bg-dark-blue-300 flex-shrink-0 flex-grow"
                      value={filterBy}
                      onChange={(e) => {
                        setFilterBy(e.target.value);
                      }}
                    >
                      <option value="drag_drop">Drag & Drop</option>
                      {(filterables ?? []).map((key: any) => (
                        <option value={key} key={key}>
                          {key}
                        </option>
                      ))}
                    </select>

                    <select
                      title="Sort by"
                      className=" p-2 rounded-lg text-[#ddd] bg-dark-blue-300  flex-shrink-0 flex-grow disabled:opacity-65"
                      name=""
                      id=""
                      disabled={filterBy === "drag_drop"}
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                    <RoundedLinkButton
                      outline
                      icon={<Done />}
                      title="Done"
                      onClick={() => {
                        onChange(modifiedItems);
                        onClose();
                      }}
                    />
                  </div>
                </div>
                <div className="pt-5"></div>

                <CardInner>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <DroppableContainer
                      droppableId="droppable"
                      className=" xw-full flex flex-col xmin-[410px]:flex-row flex-wrap gap-1 items-stretch justify-stretch"
                    >
                      {(modifiedItems ?? []).map((item, index) => (
                        <DraggableContainer
                          key={item[identifier]}
                          draggableId={item[identifier]}
                          index={index}
                          className="xmin-w-[160px] px-2 py-2 bg-[#0e63945c] hover:bg-[#07709069] cursor-move select-none rounded-lg flex-shrink-0 flex-grow"
                        >
                          <div className="flex flex-row justify-between items-center gap-1">
                            <div className="flex flex-row justify-between items-center gap-1">
                              <div>
                                <DragIndicator />
                              </div>
                              <div>
                                <div className="w-9 flex items-center justify-center text-center rounded-full xp-2 bg-[#0f4f6f6b] aspect-square ">
                                  {index}
                                </div>
                              </div>
                              <div>
                                <p>{item[title]}</p>
                                <p>{subtitle && item[subtitle]}</p>
                              </div>
                            </div>

                            <div className="flex flex-col items-center justify-between">
                              <DragIndicator />
                              {/* <KeyboardArrowUp
                                                                        className="cursor-pointer hover:bg-[#70707067] rounded-lg overflow-hidden" />
                                                                    <KeyboardArrowDown
                                                                        className="cursor-pointer hover:bg-[#70707067] rounded-lg overflow-hidden" /> */}
                            </div>
                          </div>
                        </DraggableContainer>
                      ))}
                    </DroppableContainer>
                  </DragDropContext>
                </CardInner>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
