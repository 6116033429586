import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./app/pages/dashboard/dashboard";
import Settings from "./app/pages/dashboard/settings";
import Apps from "./app/pages/dashboard/apps";
import AppDetails from "./app/pages/apps/app-details";
import NavItemDetails from "./app/pages/apps/nav/nav-item-details";
import { useEffect, useState } from "react";
import UnderConstruction from "./app/components/UnderConstruction";
import { AuthProvider } from "./app/providers/AuthProvider";
import { CookiesProvider } from "react-cookie";
import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient, PersistQueryClientProvider } from "@tanstack/react-query-persist-client";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});


function App() {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: localStoragePersister
      }}
    >
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/apps" element={<Apps />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/apps/:app_id" element={<AppDetails />} />
              <Route
                path="/apps/:app_id/nav/:nav_item_slug"
                element={<NavItemDetails />}
              />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </CookiesProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
