"use client";

import {
    Add,
    ArrowBack,
    Article,
    Cancel,
    Category,
    CollectionsBookmark,
    Delete,
    DeleteSweep,
    Done,
    Edit,
    EditNote,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    MoreVert,
    OpenInFull,
    OpenInNew,
    Refresh,
    Save,
    Sort,
    TableRows,
} from "@mui/icons-material";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomTypes } from "../../_types/CustomTypes";
import FetcherProvider, { useFetcher } from "../../_utils/fetcher/fetcher";
import ContentPreviewerDoc from "./content-previewer/ContentPreviewerDoc";
import DropDownMenuButton from "../../components/drop-down/DropDownMenuButton";
import Clickable from "../../components/Clickable";
import ModalContentEditor from "../../components/modals/ModalContentEditor";
import falsyFetch from "../../_utils/falsyFetch";
import CardTitle from "../../components/cards/card/CardTitle";
import ModalActionDialog from "../../components/modals/ModalActionDialog";
import ModalCategoryEditor from "../../components/modals/ModalCategoryEditor";
import ModalDraggableItemEditor from "../../components/modals/ModalDraggableItemEditor";
import { set } from "lodash";
import ContentPreviewerVideo from "./content-previewer/ContentPreviewerVideo";
import ContentPreviewerDropdown from "./content-previewer/ContentPreviewerDropdown";
import useGetCategoryWithChildQuery from "../../query/category/get-category-with-child.query";

type CatResType =
    | (CustomTypes.CategoryType & {
        child: Array<CustomTypes.CategoryType>;
        content: CustomTypes.ContentType;
    })
    | null;

export default function CategorySection() {
    const params = useParams();
    // const router = useRouter();
    const [parentCategoryId, setParentCategoryId] = useState<number>(-1);
    const [activeCategoryId, setActiveCategoryId] = useState<number>(-1);

    const [categoriesToSort, setCategoriesToSort] = useState<
        CustomTypes.CategoryType[]
    >([]);
    const [categoryToDelete, setCategoryToDelete] =
        useState<CustomTypes.CategoryType | null>(null);
    const [categoryToEdit, setCategoryToEdit] =
        useState<CustomTypes.CategoryType | null>(null);

    const [contentToEdit, setContentToEdit] =
        useState<CustomTypes.ContentType | null>(null);

    const { data: catSiblingsData,
        error: catSiblingsError,
        isLoading: catSiblingsIsLoading,
        refetch: catSiblingsRefetch
    } = useGetCategoryWithChildQuery({
        payload: {
            offset: 0,
            limit: -1,
            app_id: Number(params.app_id),
            nav_item_slug: params.nav_item_slug,
            category_id: parentCategoryId,
        }
    });

    const { data: catChildData,
        error: catChildError,
        isLoading: catChildIsLoading,
        refetch: catChildRefetch
    } = useGetCategoryWithChildQuery(
        {
            payload: {
                offset: 0,
                limit: -1,
                app_id: Number(params.app_id),
                nav_item_slug: params.nav_item_slug,
                category_id: activeCategoryId,
            },
            enabled: activeCategoryId > 0,
        }
    );

    // updatecatSiblingsBinder({})


    const ContentContainer = memo(function ContentContainer({
        content,
    }: {
        content: CustomTypes.ContentType | null;
    }) {
        return content ? (
            content.content_type_id === 1 ? (
                <ContentPreviewerDoc doc={content.doc || ""} />
            ) : content.content_type_id === 2 ? (
                <ContentPreviewerVideo videos={content.videos} />
            ) : (
                <ContentPreviewerDropdown dropdowns={content.dropdowns} />
            )
        ) : null;
    });

    useEffect(() => {
        setParentCategoryId(-1);
        setActiveCategoryId(-1);
    }, [params]);

    // useEffect(() => {
    //     functionHandler.loadCategory();
    // }, [parentCategoryId, activeCategoryId, params]);

    function CategoryListItem({
        category,
        active,
    }: {
        category: CustomTypes.CategoryType;
        active?: boolean;
    }) {
        return (
            <div
                className={`px-1 w-full flex flex-row items-center justify-between xrounded-lg hover:bg-[#11456739]  ${active ? "bg-[#2e688a2f]" : "cursor-pointer"
                    } group`}
            >
                <div className="group-hover:visible invisible">
                    <DropDownMenuButton
                        data={[
                            {
                                title: "Open",
                                icon: <OpenInNew sx={{ fontSize: "18px" }} />,
                                onClick() {
                                    // functionHandler.loadCategory(category);
                                    functionHandler.setActiveCategory(category);
                                },
                            },
                            {
                                title: "Edit category",
                                icon: <Edit sx={{ fontSize: "18px" }} />,
                                onClick() {
                                    setCategoryToEdit(category);
                                },
                            },
                            {
                                title: "Delete category",
                                icon: <Delete sx={{ fontSize: "18px" }} />,
                                onClick() {
                                    setCategoryToDelete(category);
                                },
                            },
                        ]}
                    >
                        <MoreVert />
                    </DropDownMenuButton>
                </div>
                <div
                    className={` p-1 select-none xw-full flex-shrink-0 flex-grow flex items-center justify-start `}
                    onClick={() => {
                        // functionHandler.loadCategory(category);
                        functionHandler.setActiveCategory(category);
                    }}
                >
                    <img

                        style={{
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            overflow: 'hidden',
                            filter: category.thumbnail ? '' : 'invert()'
                        }}

                        src={category.thumbnail || '/assets/images/ic_category.png'} alt={category.title}></img>
                    <span className="ps-2">{category.title}</span>
                </div>
                {/* <Clickable
        className="h-full p-1 text-center rounded-md flex-shrink flex-grow-0 hover:bg-[#dddddd25] active:bg-[#dddddd12]"
        onClick={() => {

        }}
    >
        <Delete fontSize="small" />
    </Clickable> */}
            </div>
        );
    }

    function FirstSection() {
        return (
            <div className="first-section w-[40%] xmin-w-[200px] overflow-hidden text-wrap">
                {/* first section */}
                <div
                    className="xpt-0.5 xps-0.5 pe-0.5 bg-[#dddddd24] border-b-2 border-b-[#ffffff7c] ">
                    <div className=' flex flex-row-reverse justify-start items-center'>
                        <DropDownMenuButton
                            data={[
                                {
                                    title: "Refresh",
                                    icon: <Refresh sx={{ fontSize: "18px" }} />,
                                    onClick() {
                                        catSiblingsRefetch();
                                        catChildRefetch();
                                    },
                                },
                                {
                                    title: "Sort",
                                    icon: <Sort sx={{ fontSize: "18px" }} />,
                                    onClick() {
                                        if (catSiblingsData?.ret_data?.child) {
                                            setCategoriesToSort(catSiblingsData.ret_data.child);
                                        }
                                    },
                                },
                            ]}
                        >
                            <MoreVert />
                        </DropDownMenuButton>
                        <div className="w-full flex items-center justify-start flex-row items-center">
                            <Clickable
                                className="pb-1"
                                onClick={() => {
                                    // functionHandler.loadCategory();
                                    setParentCategoryId(-1);
                                    setActiveCategoryId(-1);
                                }}
                            >
                                <Home fontSize="small" />
                            </Clickable>

                            {parentCategoryId !== -1 ? (
                                <Clickable
                                    className="overflow-hidden flex items-center rounded-md hover:bg-[#4980a585] active:bg-[#505c649b]"
                                    onClick={() => {
                                        setParentCategoryId((o) => {
                                            setActiveCategoryId(parentCategoryId);

                                            return catSiblingsData?.ret_data?.parent_category_id || -1;
                                        });
                                    }}
                                >
                                    <KeyboardArrowLeft />

                                    <div
                                        className={` flex items-center overflow-hidden font-semibold flex-shrink-0 flex-grow `}
                                        title={catSiblingsData?.ret_data?.title || ""}
                                    >

                                        <img
                                            style={{
                                                height: 18,
                                                width: 18,
                                                borderRadius: 9,
                                                overflow: 'hidden',
                                                filter: catSiblingsData?.ret_data?.thumbnail ? '' : 'invert()'
                                            }}

                                            src={catSiblingsData?.ret_data?.thumbnail || '/assets/images/ic_category.png'} alt={catSiblingsData?.ret_data?.title}>

                                        </img>
                                        <span className="ps-2  text-nowrap text-ellipsis "> {catSiblingsData?.ret_data?.title || ""}</span>
                                    </div>


                                </Clickable>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    // droppableId="droppable"
                    className="xp-2"
                >
                    <>
                        <div
                            className={`flex gap-2 items-center px-2 cursor-pointer p-1 xrounded-lg hover:bg-[#11456739] select-none xw-full flex-shrink-0 flex-grow`}
                            onClick={() => {
                                // return alert(
                                //   "add : " + JSON.stringify(catSiblingsData?.ret_data)
                                // );
                                setCategoryToEdit({
                                    category_id: null as any,
                                    app_id: Number(params.app_id) || null,
                                    nav_item_slug: params.nav_item_slug as string,
                                    child_type: "category",
                                    parent_category_id: parentCategoryId, //data.category_id,
                                    title: "",
                                });
                            }}
                        >
                            <Add fontSize="small" />
                            <p>Add category</p>
                        </div>
                        <div className="bg-[#dddddd30] pt-[1px] xmy-1"></div>

                        {(catSiblingsData?.ret_data?.child || []).map((category, index) => (
                            <CategoryListItem
                                key={category.category_id}
                                category={category}
                                active={activeCategoryId === category.category_id}
                            />
                        ))}
                    </>
                </div>
                {/* </DragDropContext> */}
            </div>
        );
    }


    function SecondSection() {
        const data = catChildData?.ret_data;

        const showAddCategoryButton =
            data && data.category_id >= 0 && data.child_type === "category";

        const showAddContentButton =
            data &&
            data.category_id >= 0 &&
            (data.child.length === 0 || data.child_type === "content");
        const content: CustomTypes.ContentType | null = data?.content || null;


        return (
            <div className="w-[60%]  flex-shrink-0 xflex-grow border-l-[1px] border-l-[#dddddd53]">
                {" "}
                {/* second section */}
                <div
                    className="xpt-0.5 py-0.5 ps-2 pe-0.5 flex flex-row-reverse justify-between gap-1 items-center bg-[#dddddd24] border-b-2 border-b-[#ffffff7c] ">
                    {activeCategoryId > -1 ? (
                        <>

                            <DropDownMenuButton
                                data={[
                                    {
                                        title: "Refresh",
                                        icon: <Refresh sx={{ fontSize: "18px" }} />,
                                        onClick() {
                                            catSiblingsRefetch();
                                            catChildRefetch();
                                        },
                                    },
                                    {
                                        title:
                                            catChildData?.ret_data?.child_type === "content"
                                                ? "Edit Content"
                                                : "Sort",
                                        icon:
                                            catChildData?.ret_data?.child_type === "content" ? (
                                                <EditNote sx={{ fontSize: "18px" }} />
                                            ) : (
                                                <Sort sx={{ fontSize: "18px" }} />
                                            ),
                                        onClick() {
                                            if (catChildData?.ret_data?.child_type === "content") {
                                                setContentToEdit(catChildData?.ret_data.content);
                                                return;
                                            } else if (catChildData?.ret_data?.child) {
                                                setCategoriesToSort(catChildData?.ret_data.child);
                                            }
                                        },
                                    },
                                    {
                                        title: "Edit category",
                                        icon: <Edit sx={{ fontSize: "18px" }} />,
                                        onClick() {
                                            setCategoryToEdit(catChildData?.ret_data as any);
                                        },
                                    },
                                    {
                                        title: "Delete category",
                                        icon: <Delete sx={{ fontSize: "18px" }} />,
                                        onClick() {
                                            setCategoryToDelete(catChildData?.ret_data as any);
                                        },
                                    },
                                    // {
                                    //   title: "Delete fields",
                                    //   icon: <DeleteSweep sx={{ fontSize: "18px" }} />,
                                    //   onClick() {},
                                    // },
                                ]}
                            >
                                <MoreVert />
                            </DropDownMenuButton>
                            <div className="w-full overflow-hidden text-center">
                                <div className="flex flex-row justify-start items-center ">


                                    <img
                                        style={{
                                            height: 18,
                                            width: 18,
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            filter: catChildData?.ret_data?.thumbnail ? '' : 'invert()'
                                        }}

                                        src={catChildData?.ret_data?.thumbnail || '/assets/images/ic_category.png'}
                                        alt={catChildData?.ret_data?.title}>

                                    </img>
                                    <div>

                                        <p
                                            className="text-ellipsis font-semibold ps-1 h-[22px] text-center"
                                            title={catChildData?.ret_data?.title || ""}
                                        >
                                            {catChildData?.ret_data?.title || ''}
                                        </p>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>&nbsp;</p>
                    )}
                </div>
                <>
                    {showAddCategoryButton && (
                        <>
                            <div
                                className={`flex gap-2 items-center px-2 cursor-pointer p-1 xrounded-lg hover:bg-[#11456739] select-none xw-full flex-shrink-0 flex-grow`}
                                onClick={() => {
                                    if (!data) return;

                                    setCategoryToEdit({
                                        app_id: Number(params.app_id) || null,
                                        nav_item_slug: params.nav_item_slug as string,
                                        category_id: null as any,
                                        title: "",
                                        child_type: "category",
                                        parent_category_id: data.category_id,
                                    });
                                    // console.log("cattoedit: ", data);
                                }}
                            >
                                <Add fontSize="small" />
                                <p>Add category</p>
                            </div>
                            <div className="bg-[#dddddd30] pt-[1px] xmy-1"></div>
                            {showAddContentButton && (
                                <>
                                    <div
                                        className={`flex gap-2 items-center px-2 cursor-pointer p-1 rounded-lg hover:bg-[#11456739] select-none xw-full flex-shrink-0 flex-grow`}
                                        onClick={() => {
                                            if (!data) return;

                                            setContentToEdit({
                                                content_id: null,
                                                content_type_id: 1,
                                                category_id: data?.category_id,
                                                doc: "",
                                            });

                                            // console.log('contenttoedit: ', data);
                                        }}
                                    >
                                        <Add fontSize="small" />
                                        <p>Add Content</p>
                                    </div>

                                    <div className="bg-[#dddddd30] pt-[1px] xmy-1"></div>
                                </>
                            )}
                        </>
                    )}

                    {activeCategoryId > 0 && (data?.child || []).map((category) => (
                        <CategoryListItem
                            key={category.category_id}
                            category={category}
                        />
                    ))}
                    {/* hi */}

                    <ContentContainer content={content} />
                </>
            </div>
        );
    }


    const functionHandler = {
        upsertCategories(mcategories: CustomTypes.CategoryType[]) {
            falsyFetch("/api/category/upsert", {
                categories: mcategories,
                app_id: Number(params.app_id)
            })
                .then((res) => {
                    if (res.error) {
                        return alert(res.error.message);
                    }

                    catSiblingsRefetch();
                    catChildRefetch();
                })
                .catch((err) => {
                    alert(err.message);
                });
        },
        setActiveCategory(category: CustomTypes.CategoryType) {
            setActiveCategoryId(category.category_id);
            setParentCategoryId(category.parent_category_id);
        },

        deleteCategory(category: CustomTypes.CategoryType) {
            falsyFetch("/api/category/delete", {
                category_id: category.category_id,
                app_id: Number(params.app_id)
            })
                .then((res) => {
                    if (res.error) {
                        return alert(res.error.message);
                    }

                    setCategoryToDelete(null);

                    catSiblingsRefetch();
                    catChildRefetch();
                })
                .catch((err) => {
                    alert(err.message);
                });
        },
    };

    return (
        <>
            <ModalContentEditor
                isOpen={!!contentToEdit}
                content={contentToEdit}
                onChange={(content) => {
                    setContentToEdit(null);

                    catSiblingsRefetch();
                    catChildRefetch();
                }}
                onClose={() => {
                    setContentToEdit(null);
                }}
            />

            <ModalCategoryEditor
                isOpen={!!categoryToEdit}
                category={categoryToEdit}
                onChange={(category) => {
                    setCategoryToEdit(null);
                    catSiblingsRefetch();
                    catChildRefetch();
                }}
                onClose={() => {
                    setCategoryToEdit(null);
                }}
            />

            <ModalActionDialog
                isOpen={!!categoryToDelete}
                onClose={() => {
                    setCategoryToDelete(null);
                }}
                title="Warning !"
                innerContent={
                    <p>
                        Are you sure, you wan&apos;t to delete&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                            &quot;{categoryToDelete?.title}&quot;
                        </span>
                        &nbsp;?
                    </p>
                }
                actions={[
                    {
                        icon: <Cancel />,
                        title: "No",
                        position: "right",
                        onClick() {
                            setCategoryToDelete(null);
                        },
                    },
                    {
                        icon: <Done />,
                        title: "Yes",
                        position: "right",
                        onClick() {
                            categoryToDelete &&
                                functionHandler.deleteCategory(categoryToDelete);
                        },
                    },
                ]}
            />

            <ModalDraggableItemEditor
                isOpen={categoriesToSort.length > 0}
                onChange={functionHandler.upsertCategories}
                onClose={() => {
                    setCategoriesToSort([]);
                }}
                items={categoriesToSort}
                identifier="category_id"
                ordinal="ordinal"
                filterables={["title", "category_id", "child_type"]}
                title="title"
            />

            <CardTitle text="Categories" />

            <div className="pt-3"></div>

            <div className=" w-full rounded-lg p-2 bg-dark-blue-100">
                {/* <div className="category-tree py-2 w-full flex flex-row overflow-auto flex-nowrap text-nowrap flex-shrink-0 items-center select-none">
          <Clickable
            className="pb-1"
            onClick={() => {
              // functionHandler.loadCategory();
              setParentCategoryId(-1);
              setActiveCategoryId(-1);
            }}
          >
            <Home fontSize="small" />
          </Clickable>
        </div> */}

                <div className="rounded-md bg-[#04161e69] overflow-hidden w-full">
                    <div className="w-full flex flex-row flex-nowrap xgap-0.5 items-start justify-stretch">
                        <FirstSection />
                        <SecondSection />
                    </div>
                </div>
            </div>
        </>
    );
}

