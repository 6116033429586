
import {
  ArrowBack,
  Battery60,
  CropSquare,
  Home,
  Menu,
  SimCard,
  Square,
} from "@mui/icons-material";
import { useEffect, useRef } from "react";
import Log from "../../../_utils/log/Log";
import { CustomTypes } from "../../../_types/CustomTypes";
import VideoListItem from "../../../components/list-item/VideoListItem";


export default function ContentPreviewerVideo({
  videos,
}: {
  videos: CustomTypes.VideosType[];
}) {
  Log.exp("videos content: ", videos);

  return (
    <div className="flex w-full items-center justify-center py-2 px-0.5 sm:px-2">
      <div className=" flex flex-col w-full xh-[400px] py-2 flex-grow-0 flex-shrink xh-full overflow-hidden">
        <div className="overflow-hidden h-full  w-full xopacity-0">
          {(videos || []).map((video, i) => (
            <VideoListItem
              key={video.content_video_id}
              index={i}
              item={video}
              thumbWidth={80}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

