/*eslint-disable import/first */
"use client";;
import { Add } from "@mui/icons-material";
import CardTitle from "../../components/cards/card/CardTitle";
import AppCard from "../../components/cards/dashboard/AppCard";
import RoundedLinkButton from "../../components/link/RoundedLinkButton";
import DashboardLayout from "./dashboard-layout";
import { useEffect, useState } from "react";
import { CustomTypes } from "../../_types/CustomTypes";
import FetcherProvider, { useFetcher } from "../../_utils/fetcher/fetcher";
import ModalAddApp from "../../components/modals/ModalAddApp";
import { useNavigate } from "react-router";
import useGetAppsQuery from "../../query/category/get-apps.query";

export default function Apps() {
  // const [apps, setApps] = useState<Array<CustomTypes.AppType>>([]);
  const [isAddAppDialogVisible, setIsAddAppDialogVisible] = useState(false);

  const { data, error, isLoading, refetch } = useGetAppsQuery({
    payload: {
      limit: -1,
      offset: 0,
    }
  });

  const navigate = useNavigate();


  return (
    <DashboardLayout>
      <ModalAddApp
        onClose={function (): void {
          setIsAddAppDialogVisible(false);
        }}
        isOpen={isAddAppDialogVisible}
        onSave={function (appId: number): void {
          navigate("/apps/" + appId);
        }}
      />
      <main>
        <div className="pt-5"></div>
        <div className="flex flex-row flex-wrap gap-3">
          <CardTitle text="Apps" />
          <RoundedLinkButton
            onClick={() => {
              setIsAddAppDialogVisible(true);
            }}
            icon={<Add />}
            iconPosition="end"
            title="Add app"
          />
        </div>
        <div className="pt-5"></div>
        <div className="flex flex-row flex-wrap  flex-shrink gap-3">
          <>
            {(data?.ret_data || []).map((app) => (
              <AppCard key={app.app_id} app={app} />
            ))}
          </>
        </div>
      </main>
    </DashboardLayout>
  );
}