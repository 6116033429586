import { useQuery } from "@tanstack/react-query"
import falsyFetch from "../../_utils/falsyFetch"
import { useState } from "react"
import { CustomTypes } from "../../_types/CustomTypes"

type PayloadType = { limit: number; offset: number };

const useGetAppsQuery = ({ payload, enabled = true }: { payload: PayloadType, enabled?: boolean }) => {

    const { data, error, isLoading, isFetching, refetch } = useQuery({
        queryKey: ["QUERY_GET_APPS", payload],
        queryFn: (): Promise<CustomTypes.RetData<Array<CustomTypes.AppType>>> => falsyFetch("/api/app/paginate", payload),
        enabled: enabled
    })



    return {
        data,
        error,
        isLoading: isLoading || isFetching,
        refetch
    }

}

export default useGetAppsQuery;