"use client";

import { SignLanguage } from "@mui/icons-material";
import CardInner from "../card/CardInner";
import CardSubtitle from "../card/CardSubitle";
import CardTitle from "../card/CardTitle";
import CardWrapper from "../card/CardWrapper";
import FeatureRow from "./FeatureRow";
import CardWrapperCl from "../card/CardWrapperCl";
import { useNavigate } from "react-router-dom";
import { CustomTypes } from "../../../_types/CustomTypes";
// import { useRouter } from "next/navigation";

export default function AppCard({
  grow = true,
  app,
}: {
  grow?: boolean;
  app: CustomTypes.AppType;
}) {
  // const router = useRouter();
  const navigate = useNavigate();

  return (
    <CardWrapperCl
      grow={grow}
      hover
      onClick={() => {
        navigate("/apps/" + app.app_id);
      }}
    >
      <div className="p-1 min-w-[150px]">
        <CardTitle text={app.title} />

        <CardSubtitle text={app.package_name} />

        <div className="py-12"></div>
      </div>
    </CardWrapperCl>
  );
}
