import { useQuery } from "@tanstack/react-query"
import falsyFetch from "../../_utils/falsyFetch"
import { useState } from "react"
import { CustomTypes } from "../../_types/CustomTypes"

type CatResType =
    | (CustomTypes.CategoryType & {
        child: Array<CustomTypes.CategoryType>;
        content: CustomTypes.ContentType;
    })
    | null;

const useGetCategoryWithChildQuery = ({ payload, enabled = true }: { payload: CustomTypes.CategoryPayloadType, enabled?: boolean }) => {
    //"/api/category/paginate-with-child"
    const { data, error, isLoading, isFetching, refetch } = useQuery({
        queryKey: ["QUERY_GET_CATEGORY_WITH_CHILD", payload],
        queryFn: (): Promise<CustomTypes.RetData<CatResType>> => falsyFetch<CustomTypes.CategoryPayloadType>("/api/category/paginate-with-child", payload),
        enabled: enabled
    })



    return {
        data,
        error,
        isLoading: isLoading || isFetching,
        refetch
    }

}

export default useGetCategoryWithChildQuery;