 /* eslint-disable import/first */

"use client";;
import { AutoFixHigh } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import AppLayout from "../app-layout";
import MoreAppsFragment from "../../../fragments/MoreAppsFragment";
import NavItemFragment from "../../../fragments/NavItemFragment";

export default function NavItemDetails() {
  const { nav_item_slug } = useParams();

  console.log(new RegExp("item\\d+").test(nav_item_slug as string));

  return (
    <AppLayout>
      {new RegExp("item\\d+").test(nav_item_slug as string) ? (
        <NavItemFragment />
      ) : new RegExp("more-app").test(nav_item_slug as string) ? (
        <MoreAppsFragment />
      ) : (
        <main className="h-full w-full">
          <div className=" text-center relative top-[50%]  translate-y-[-50%]">
            <AutoFixHigh
              fontSize="large"
              sx={{
                fontSize: 120,
                p: 4,
                borderRadius: "50%",
                backgroundColor: "#00000015",
              }}
            />
            <div className="text-[#4979c0] font-bold text-2xl pt-5 pb-2">
              Nothing to do here!
            </div>
            <div className="text-xs">
              This section is not customizable from Admin Panel
            </div>
          </div>
        </main>
      )}
    </AppLayout>
  );
}

