
"use client";

import { ClickAwayListener } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import { Close, Edit, Save } from "@mui/icons-material";
import TextInputNormal from "../input/TextInputNormal";
import { CustomTypes } from "../../_types/CustomTypes";
import falsyFetch from "../../_utils/falsyFetch";
import ModalMediaEditor from "./ModalMediaEditor";
import _ from "lodash";
import { useParams } from "react-router-dom";

type ModalProps = {
  category: CustomTypes.CategoryType | null;
  onClose: () => void;
  isOpen: boolean;
  onChange: (category: CustomTypes.CategoryType) => void;
};

export default function ModalCategoryEditor({
  category,
  onClose,
  isOpen,
  onChange,
}: ModalProps) {
  const [mCategory, setMCategory] = useState<CustomTypes.CategoryType | null>(
    null
  );


  const [isMediaEditorOpen, setIsMediaEditorOpen] = useState(false);

  const params = useParams();


  // const [callback, setCallback] = useState<any>(null);

  useEffect(() => {
    if (isOpen) setMCategory(category);
  }, [isOpen, category]);

  const functionHandler = {
    onSave() {
      if (!mCategory) return;
      falsyFetch<object>("/api/category/upsert", {
        categories: [mCategory],
        app_id: Number(params.app_id)
      })
        .then((e) => {
          if (e.error) {
            alert(e.error.message);
            return;
          }
          onChange(mCategory);
        })
        .catch((e) => {
          alert(e);
        });
    },

    updateCategoryThumbnail(url: any) {

      setMCategory((c) => {

        if (c == null) return c;


        const _c = _.cloneDeep(c);
        _c.thumbnail = url;
        return _c;
      })


    }
  };

  const visibility =  isOpen ? "block" : "hidden";

  return (
    <div
      id="modal-media-editor-top"
      className={`  ${visibility}  fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50 flex flex-col items-center justify-center `}
    >

      <ModalMediaEditor
        imageUrl={mCategory?.thumbnail}
        onChange={(url: any) => {
          // setItemIconSrcEditor(url);
          functionHandler.updateCategoryThumbnail(url);
        }}
        isOpen={isMediaEditorOpen}
        onClose={() => {
          setIsMediaEditorOpen(false);
        }}
      />

      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            // onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    max-w-[650px] max-h-[95dvh] overflow-y-auto w-full"
          // ref={scrollerRef}
          >
            <div className="w-full">
              <div className="bg-dark-blue-100">
                <div className="sticky top-0 z-10 bg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                  <CardTitle
                    text={`${mCategory?.category_id ? "Edit" : "Add"} category`}
                  />
                  <RoundedLinkButton
                    icon={<Close fontSize="small" />}
                    onClick={onClose}
                  />
                </div>
                <div className="pt-2"></div>

                <CardInner>
                  <div className="flex flex-col items-center justify-stretch gap-5 pt-5 px-5 w-full">

                    <div className="relative w-[80px] h-[80px] mx-auto  xpx-5  xmy-5 xpt-1 xpb-5 gap-2 rounded-lg bg-[#ffffff42] flex flex-col items-center justify-center">

                      <img
                        className="xmt-2 rounded-sm overflow-hidden"
                        width={80}
                        height={80}
                        alt={mCategory?.title}
                        src={mCategory?.thumbnail || ""}
                      />

                      <div
                        className="absolute top-[20px] rounded-full p-2 cursor-pointer text-[#192d39] hover:text-[#acdfff] bg-[#ffe9c4a4] hover:bg-[#9f5d47a4] border-2 border-[#ddd]"
                        onClick={() => {
                          setIsMediaEditorOpen(true);
                        }}
                      >
                        <Edit fontSize="medium" />
                      </div>

                    </div>


                    <TextInputNormal
                      type="text"
                      value={mCategory?.title}
                      onChange={(e) => {
                        setMCategory(
                          (o) =>
                          ({
                            ...o,
                            title: e.target.value,
                          } as any)
                        );
                      }}
                      fullWidth={true}
                    />
                    <div className="flex flex-row items-center justify-end gap-3 w-full">
                      <RoundedLinkButton
                        icon={null}
                        title="Save"
                        onClick={functionHandler.onSave}
                      />
                      <RoundedLinkButton
                        icon={null}
                        title="Cancel"
                        onClick={onClose}
                      />
                    </div>
                  </div>
                </CardInner>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

