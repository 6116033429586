import { CustomTypes } from "../_types/CustomTypes";
const Utils = {
  safeRetData(
    callback: (data: any) => void
  ): (ret: CustomTypes.RetData<any>) => void {
    return (r) => {
      if (r.error) {
        return this.handleCatchError(r.error);
      }

      callback(r.ret_data);
    };
  },
  handleCatchError(err: CustomTypes.RetError) {
    console.log("Handle catch error:: ", err);

    if (!err) return;

    if (err?.message) {
      alert(err?.message);
    } else if (err?.info) {
      alert(err?.info);
    } else alert(err);
  },
  getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
};

export default Utils;
