import {
  ArrowBack,
  Battery60,
  CropSquare,
  Home,
  Menu,
  SimCard,
  Square,
} from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { CustomTypes } from "../../../_types/CustomTypes";
import VideoListItem from "../../../components/list-item/VideoListItem";
import DropdownListItem from "../../../components/list-item/DropdownListItem";

type ContentDocType = {
  content_doc_id: number;
  content_id: number;
  doc_type_id: number;
  ordinal: number;
  value: string;
};

export default function ContentPreviewerDropdown({
  dropdowns,
}: {
  dropdowns: CustomTypes.DropdownType[];
}) {
  return (
    <div className="flex w-full items-center justify-center py-2 px-0.5 sm:px-2">
      <div className=" flex flex-col w-full xh-[400px] py-2 flex-grow-0 flex-shrink xh-full overflow-hidden">
        <div className="overflow-hidden h-full  w-full xopacity-0">
          {(dropdowns || []).map((dropdown, i) => (
            <DropdownListItem
              key={dropdown.content_dropdown_id}
              index={i}
              item={dropdown}
              thumbWidth={80}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
